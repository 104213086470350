<template>
  <div class="page-common-body collect-page-container" ref="wrapper">
    <div class="page__title">心愿单</div>
    <div class="collect-main-body" v-if="collects.length > 0">
      <div
        class="collect__item"
        v-for="(collect, index) in collects"
        :key="index"
        @click="onDel"
        :data-gid="collect.goods.uuid"
        :data-id="collect.id"
      >
        <div class="product__cover">
          <img :src="collect.goods.cover.normal | parseImage" alt="" />
        </div>
        <div class="product__info">
          <p class="info__name">
            {{ collect.goods.category.name.cn }} - {{ collect.goods.name }}
          </p>
          <p class="info__price">
            <strong>￥{{ collect.goods.price }}</strong>
          </p>
          <div class="collect-bt"></div>
        </div>
      </div>
    </div>
    <empty
      v-else-if="!requesting"
      title="无收藏商品"
      hint="您没有收藏商品br您可以在此查看已收藏的商品"
      :bt="{
        type: 'inner',
        link: '/products',
        title: '继续收藏'
      }"
    ></empty>
    <pagination
      class="collect__pagination"
      v-if="paginationConfig"
      :config="{
        total: paginationConfig.pageCount
      }"
    ></pagination>
  </div>
</template>

<script>
import pagination from "@/components/Pagination/index.vue";
import empty from "@/components/empty.vue";
import { mapMutations } from "vuex";
export default {
  name: "page-user-collect",
  components: {
    pagination,
    empty
  },
  data() {
    return {
      collects: [],
      page: 1,
      requesting: false,
      paginationConfig: null
    };
  },
  watch: {
    $route: {
      handler: "init",
      immediate: true
    }
  },
  mounted() {
    const that = this;
    that.heightFullScreen(that.$refs.wrapper, false);
  },
  methods: {
    ...mapMutations(["PAGE_LOADING", "PAGE_LOADED"]),
    init() {
      const that = this;
      that.page = that.$route.query.page || 1;
      that.collects = [];
      that.PAGE_LOADING();
      that.loadmore();
    },
    loadmore() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get("/api/user/collect", {
            page: that.page
          })
          .then(res => {
            const data = res.data;
            that.collects = data.list;
            that.paginationConfig = data._meta;
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            that.requesting = false;
            that.PAGE_LOADED();
          });
      }
    },
    onDel(e) {
      const that = this;
      const { gid, id } = e.currentTarget.dataset;
      that
        .$confirm("是否删除该收藏", "提示", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          that.$request.del(`/api/user/collect/?gid=${gid}`).then(res => {
            console.log(id);
            if (res.data === "SUCCESS") {
              that.loadmore();
            }
          });
        })
        .catch(() => {
          console.log("取消");
        });
    }
  }
};
</script>

<style></style>
